import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";
import React, {
  useTransition,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stage } from "react-konva";
import { isNil, toLower, omit, isEmpty } from "ramda";
import { observer } from "mobx-react";
import TestsDrawer from "../../../../../TestsDrawer";
import AsBuilt from "../AsBuilt";
import AsFabricated from "../AsFabricated";
import InfoBox from "../InfoBox";
import Header from "../Header";
import { getScale, fitToScreen, getStageWidth } from "../../helpers";
import { getWeldsByStalkNumber } from "../../../../helpers";
import {
  STAGE_CONFIG,
  STATE,
  VIEWS,
  DEFAULT_SCALE_BY_VIEW,
  DEFAULT_COORDINATES,
  VIEW_KEY,
  PAGE_PADDING,
  ARROW_BUTTONS_KEY_CODES,
  SETTINGS_BY_VIEW,
} from "../../constants";
import useStores from "../../../../../../../../useStores";
import Stats from "stats.js";
import qs from "qs";
import { PAGE_TABS } from "../../../../constants";
import { ENTITIES } from "../../../../../../constants";
import Footer from "../Footer";
import Tooltip from "../Tooltip";
import GlobalView from "../GlobalView";
import DetailedView from "../DetailedView";
import CoatingView from "../CoatingView";
import ImageLayer from "../ImageLayer";

let IMAGE_TIMER;

const IMAGE_TIMEOUT = 500;

const scaleStats = new Stats();
const dragStats = new Stats();

const STATS_SCALE_ID = "scale";
const STATS_DRAG_ID = "drag";

const COMPONENT_BY_STATE = {
  [STATE.AS_BUILT]: AsBuilt,
  [STATE.AS_FABRICATED]: AsFabricated,
};

const COMPONENT_BY_VIEW = {
  [VIEWS.GLOBAL]: GlobalView,
  [VIEWS.DETAILED]: DetailedView,
  [VIEWS.COATING]: CoatingView,
};

const WeldsCanvas = observer(({ container }) => {
  const { MenuStore, CampaignStore } = useStores();
  const { menuWidth } = MenuStore;

  const history = useHistory();
  const location = useLocation();

  const campaign = CampaignStore.campaign;

  const experimentalDesignEnabled = useMemo(
    () => isExperimentalDesignEnabled(),
    [],
  );

  const { stalks, tieInWeldsByStalkNumber } = useMemo(
    () => getWeldsByStalkNumber(campaign.welds, true),
    [campaign.welds],
  );
  const { stalkNumber } = qs.parse(window.location.search.slice(1));

  const stalksNumbers = useMemo(
    () => stalks.map((stalk) => stalk[0].stalkNumber),
    [stalks.length],
  );
  const stalkNumberAvailable = stalksNumbers.includes(stalkNumber);

  const stage = useRef(null);
  const layer = useRef({});
  const imageLayer = useRef(null);

  const viewSavedValue = localStorage.getItem(VIEW_KEY + campaign._id);

  const [openEntityIndex, setOpenEntityIndex] = useState(null);
  const [entitiesToView, setEntitiesToView] = useState([]);

  const [stageHeight, setStageHeight] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [state, setState] = useState(
    !isEmpty(tieInWeldsByStalkNumber) && !stalkNumberAvailable
      ? STATE.AS_BUILT
      : STATE.AS_FABRICATED,
  );
  const [view, setView] = useState(
    VIEWS[viewSavedValue] ? viewSavedValue : VIEWS.GLOBAL,
  );
  const [node, setNode] = useState({});
  const [settings, setSettings] = useState(
    SETTINGS_BY_VIEW[view](state, stalkNumberAvailable ? stalkNumber : null),
  );
  const [stageWidth, setStageWidth] = useState(
    getStageWidth(
      menuWidth,
      experimentalDesignEnabled,
      container.current?.offsetWidth,
    ),
  );

  const [coordinates, setCoordinates] = useState(null);
  const [scale, setScale] = useState(DEFAULT_SCALE_BY_VIEW[view]);

  const [layerImage, setLayerImage] = useState(null);

  const [isPending, startTransition] = useTransition();

  const isLoading = !coordinates || !stage.current || isPending;

  const search = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search],
  );

  const type = useMemo(() => {
    const { pipe } = search;

    if (pipe) return ENTITIES.PIPE;

    return ENTITIES.WELD;
  }, [location.search]);

  const addStats = useCallback((stats, id, topOffset) => {
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(stats.dom);
    stats.dom.style.position = "absolute";
    stats.dom.style.left = "100px";
    stats.dom.style.top = `${topOffset}px`;
    stats.dom.id = id;
  }, []);

  const removeStats = useCallback((id) => {
    const statsBlock = document.getElementById(id);

    if (!statsBlock) return;

    document.body.removeChild(statsBlock);
  }, []);

  const recalculateStageWidth = useCallback(() => {
    setStageWidth(
      getStageWidth(
        menuWidth,
        experimentalDesignEnabled,
        container.current?.offsetWidth,
      ),
    );
  }, [menuWidth]);

  useEffect(() => {
    if (!settings.showStats) {
      removeStats(STATS_SCALE_ID);
      removeStats(STATS_DRAG_ID);
    } else {
      addStats(scaleStats, STATS_SCALE_ID, 170);
      addStats(dragStats, STATS_DRAG_ID, 240);
    }
  }, [settings.showStats]);

  useLayoutEffect(() => {
    if (!container.current?.offsetHeight) return;

    startTransition(() => {
      setStageHeight(container.current.offsetHeight);
    });
  }, [container.current?.offsetHeight]);

  useEffect(() => {
    recalculateStageWidth();
  }, [menuWidth, isFullScreen]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  useEffect(() => {
    const instance = container.current;

    instance.addEventListener("fullscreenchange", onFullScreen);

    return () => instance.removeEventListener("fullscreenchange", onFullScreen);
  }, [view]);

  useEffect(() => {
    window.addEventListener("resize", recalculateStageWidth);

    return () => window.removeEventListener("resize", recalculateStageWidth);
  }, []);

  useEffect(() => {
    if (!stageWidth || !stageHeight || !layer.current?._id) return;

    setDefaultStageStatus();
  }, [
    settings.welds,
    settings.splitStalk,
    settings.stalkNumber,
    settings.baseMaterials,
    settings.quarantine,
    stageWidth,
    stageHeight,
    isFullScreen,
    layer.current?._id,
    view,
    state,
  ]);

  const makeSnapshot = useCallback(() => {
    if (!layer.current?._id) return;

    stage.current.off("mouseenter");

    const { width, height, x, y } = layer.current.getClientRect();
    const image = new window.Image();

    image.src = layer.current.toDataURL({
      callback: (src) => setLayerImage(src),
      quality: 1,
      pixelRatio: window.devicePixelRatio,
      x,
      y,
      width,
      height,
    });

    image.onload = () => imageLayer.current.draw();

    setLayerImage(image);
  }, []);

  const showImageLayer = useCallback(() => {
    setNode({});
    clearTimeout(IMAGE_TIMER);
    layer.current.visible(false);
    layer.current.listening(false);
    imageLayer.current.visible(true);
  }, []);

  const hideImageLayer = useCallback(() => {
    IMAGE_TIMER = setTimeout(() => {
      layer.current.visible(true);
      layer.current.listening(true);
      imageLayer.current.visible(false);
    }, IMAGE_TIMEOUT);
  }, []);

  const setDefaultStageStatus = useCallback(() => {
    if (!stage.current || !layer.current?._id) return;

    const calculatedScale = fitToScreen(layer.current, stage.current, false);
    setScale(calculatedScale);

    const { height: contentHeight } = layer.current.getClientRect({
      skipTransform: true,
    });

    const relativeContentHeight = contentHeight * calculatedScale;
    const yOffset =
      relativeContentHeight < stageHeight
        ? (stageHeight - relativeContentHeight) / 2
        : 4 * PAGE_PADDING;

    setCoordinates({ x: 0, y: yOffset });

    stage.current.on("mouseenter", makeSnapshot);
  }, [stageHeight, layer.current, stage.current]);

  const onFullScreen = useCallback(() => {
    const canvas = document.fullscreenElement;
    setIsFullScreen(!!canvas);
  }, []);

  const onKeyDown = useCallback((e) => {
    dragStats.begin();

    switch (e.keyCode) {
      case ARROW_BUTTONS_KEY_CODES.LEFT_ARROW:
        setCoordinates({
          x: stage.current.x() - STAGE_CONFIG.KEY_DRAG_DELTA,
          y: stage.current.y(),
        });
        break;
      case ARROW_BUTTONS_KEY_CODES.UP_ARROW:
        setCoordinates({
          x: stage.current.x(),
          y: stage.current.y() - STAGE_CONFIG.KEY_DRAG_DELTA,
        });
        break;
      case ARROW_BUTTONS_KEY_CODES.RIGHT_ARROW:
        setCoordinates({
          x: stage.current.x() + STAGE_CONFIG.KEY_DRAG_DELTA,
          y: stage.current.y(),
        });
        break;
      case ARROW_BUTTONS_KEY_CODES.DOWN_ARROW:
        setCoordinates({
          x: stage.current.x(),
          y: stage.current.y() + STAGE_CONFIG.KEY_DRAG_DELTA,
        });
        break;
      default:
        return;
    }

    e.preventDefault();
    dragStats.end();
  }, []);

  const zoomTo = useCallback(
    (zoomTo = {}, deltaY, scaleBy = STAGE_CONFIG.SCALE_BY) => {
      if (
        (scale <= STAGE_CONFIG.MIN_SCALE && deltaY > 0) ||
        (scale >= STAGE_CONFIG.MAX_SCALE && deltaY < 0)
      )
        return;

      scaleStats.begin();

      showImageLayer();

      const oldScale = stage.current.scaleX();

      const newScale = getScale(oldScale, deltaY, scaleBy);

      const relatedTo = {
        x: zoomTo.x / oldScale - stage.current.x() / oldScale,
        y: zoomTo.y / oldScale - stage.current.y() / oldScale,
      };

      const x = (zoomTo.x / newScale - relatedTo.x) * newScale;
      const y = (zoomTo.y / newScale - relatedTo.y) * newScale;

      setCoordinates({ x, y });
      setScale(newScale);

      hideImageLayer();

      scaleStats.end();
    },
    [scale],
  );

  const onWheel = useCallback((e) => {
    e.evt.preventDefault();

    if (e.evt.ctrlKey) {
      // zoom
      // how to scale? Zoom in? Or zoom out?
      const deltaY = e.evt.deltaY > 0 ? 1 : -1;

      const currentMousePosition = stage.current.getPointerPosition();

      zoomTo(currentMousePosition, deltaY, STAGE_CONFIG.SCALE_BY);
    } else {
      // drag
      dragStats.begin();

      showImageLayer();
      const deltaX = -e.evt.deltaX / STAGE_CONFIG.DRAG_DELTA;
      const deltaY = -e.evt.deltaY / STAGE_CONFIG.DRAG_DELTA;

      setCoordinates({
        x: stage.current.x() + deltaX,
        y: stage.current.y() + deltaY,
      });
      hideImageLayer();
      dragStats.end();
    }
  }, []);

  const zoomOnClick = useCallback(
    (deltaY, scaleBy = STAGE_CONFIG.SCALE_BY) => {
      const center = {
        x: stageWidth / 2,
        y: stageHeight / 2,
      };

      zoomTo(center, deltaY, scaleBy);
    },
    [stageWidth, stageHeight],
  );

  const onDragStart = useCallback((e) => {
    setCoordinates({
      x: e.target.x(),
      y: e.target.y(),
    });

    onMouseMove(e);

    showImageLayer();
  }, []);

  const onDragEnd = useCallback((e) => {
    setCoordinates({
      x: e.target.x(),
      y: e.target.y(),
    });

    onMouseMove(e);

    hideImageLayer();
  }, []);

  const onMouseMove = useCallback(
    (e) => {
      e.cancelBubble = true;

      const shape = e.target;

      const { x, y } = stage.current.getPointerPosition();

      const mousePointTo = {
        x: x / scale - stage.current.x() / scale,
        y: y / scale - stage.current.y() / scale,
      };

      setNode({
        x: mousePointTo.x,
        y: mousePointTo.y,
        id: shape.id(),
        data: shape.attrs.data,
      });
    },
    [scale],
  );

  const onChangeView = useCallback(
    (newView) => {
      startTransition(() => {
        setView(newView);
        setSettings(SETTINGS_BY_VIEW[newView](state, settings.stalkNumber));
        setCoordinates(null);
        setLayerImage(null);
      });
    },
    [state, settings],
  );

  const onChangeState = useCallback(
    (newState) => {
      if (state === newState) return;

      startTransition(() => {
        setState(newState);
        setSettings(SETTINGS_BY_VIEW[view](newState));
        setCoordinates(null);
        setLayerImage(null);
      });
    },
    [view, state, settings],
  );

  const onCloseDrawer = useCallback(() => {
    setOpenEntityIndex(null);

    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
      }),
    });
  }, []);

  const navigate = useCallback(
    (item, entity) => {
      history.push({
        pathname: history.pathname,
        search: qs.stringify({
          ...omit([ENTITIES.WELD, ENTITIES.PIPE], search),
          [entity]:
            entity === ENTITIES.PIPE ? item.pipeNumber : item.weldNumber,
        }),
      });
    },
    [location.search],
  );

  const State = COMPONENT_BY_STATE[state];
  const View = COMPONENT_BY_VIEW[view];

  return (
    <>
      {isLoading && <InfoBox text="Loading..." />}
      <Header
        state={state}
        view={view}
        onChangeView={onChangeView}
        onChangeState={onChangeState}
        isFullScreen={isFullScreen}
        tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
      />
      <Footer
        container={container}
        settings={settings}
        setSettings={setSettings}
        isFullScreen={isFullScreen}
        view={view}
        onChangeView={onChangeView}
        state={state}
        stageWidth={stageWidth}
        zoomBy={zoomOnClick}
        setDefaultStageStatus={setDefaultStageStatus}
        scale={scale}
        stalksNumbers={stalksNumbers}
      />
      <TestsDrawer
        index={openEntityIndex}
        open={!isNil(openEntityIndex)}
        close={onCloseDrawer}
        pipes={entitiesToView.pipes}
        welds={entitiesToView.welds}
        type={type}
        navigate={navigate}
      />
      {stageHeight && stageWidth && (
        <Stage
          ref={stage}
          width={stageWidth}
          height={stageHeight}
          scaleX={scale}
          scaleY={scale}
          x={coordinates ? coordinates.x : DEFAULT_COORDINATES.x}
          y={coordinates ? coordinates.y : DEFAULT_COORDINATES.y}
          draggable
          onWheel={onWheel}
          onMouseMove={onMouseMove}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragMove={onMouseMove}
        >
          <State
            setEntitiesToView={setEntitiesToView}
            setOpenEntityIndex={setOpenEntityIndex}
            settings={settings}
            stageWidth={stageWidth}
            coordinates={coordinates || DEFAULT_COORDINATES}
            scale={scale}
            layer={layer}
            state={state}
            stalks={stalks}
            tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
            stalksNumbers={stalksNumbers}
            Component={View}
          />
          <Tooltip node={node} scale={scale} stage={stage} />
          <ImageLayer
            layer={layer}
            imageLayer={imageLayer}
            layerImage={layerImage}
          />
        </Stage>
      )}
    </>
  );
});

export default memo(WeldsCanvas);
