import React from "react";
import { Box } from "@mui/material";
import MenuItem from "../MenuItem";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MenuGroup = ({ title, items, expanded, handleClick, activeTab }) => {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {!!(title && expanded) && (
        <Box>
          <Box
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {title}
          </Box>
        </Box>
      )}
      {items.map((menuItem, index) => (
        <Box key={`menuItem-${index}`}>
          <MenuItem menuItem={menuItem} expanded={expanded} />
        </Box>
      ))}
    </Box>
  );
};

export default MenuGroup;
