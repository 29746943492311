import AddDataDrawer from "@core/components/AddDataDrawer";
import MultipleSigningModal from "@core/components/MultipleSigningModal";
import modules from "@core/constants/modules";
import { TestService } from "@core/services";
import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { pick, values } from "ramda";
import React, { useState } from "react";
import { RiFileAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { TRANSACTION_TYPES } from "@core/constants/transactions";
import useStores from "../../../../../../useStores";
import styles from "./styles";
import SignAllTestsButton from "./components/SignAllTestsButton";
import ImportData from "./components/ImportData";
import CreateTests from "@core/components/CreateTests";

const TestActions = observer(({ classes }) => {
  const { UserStore, CampaignStore } = useStores();

  const [addTestsOpen, setAddTestsOpen] = useState(false);

  const [module] = UserStore.user.data.company.modules;

  const campaign = CampaignStore.campaign;
  const { tests = [], type = "" } = campaign;

  const { id: campaignId } = useParams();

  const campaignTestIds = tests.map((test) => test._id);

  const onTestsCreated = async (newTests) => {
    const testsIds = newTests.map((test) => test._id);
    await CampaignStore.updateCampaignById(
      campaignId,
      { tests: campaignTestIds.concat(testsIds) },
      { tests: tests.concat(newTests) },
    );
  };

  const assignTests = async (testsToAssign, createdTests) => {
    const tests = testsToAssign.reduce((acc, test) => {
      const campaignTest =
        createdTests.find(
          (t) =>
            !t.assignee &&
            t.type === test.type &&
            t.status === test.status &&
            !acc[t._id],
        ) || {};

      acc[campaignTest._id] = {
        ...campaignTest,
        ...pick(["assignee", "witnesses"], test),
      };

      return acc;
    }, {});
    await TestService.assign(
      values(tests),
      campaign,
      TRANSACTION_TYPES.CAMPAIGN,
    );
  };

  const importData = ({ tests, rawMaterials, welds }) => {
    CampaignStore.uploadTests(campaignId, tests, rawMaterials, welds);
  };

  if (module.name !== modules.PRODUCER) return null;

  return (
    <>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item>
          <Button
            className={classes.addTestsButton}
            color="primary"
            variant="contained"
            onClick={() => setAddTestsOpen(true)}
          >
            <RiFileAddFill size={18} />
            &nbsp;<span>Add tests</span>
          </Button>
          <MultipleSigningModal
            tests={tests}
            entity={campaign}
            link={`/campaigns/${type.toLowerCase()}/${campaignId}`}
            updateTests={(test, changes) =>
              CampaignStore.updateTestById(test._id, changes)
            }
            triggerButton={SignAllTestsButton}
          />
        </Grid>
      </Grid>
      <AddDataDrawer
        open={addTestsOpen}
        close={() => setAddTestsOpen(false)}
        ImportData={ImportData}
        CreateTests={CreateTests}
        assignTests={assignTests}
        onTestsCreated={onTestsCreated}
        importData={importData}
      />
    </>
  );
});

export default withStyles(TestActions, styles);
