import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import qs from "qs";
import { TABLE_PAGE } from "@core/constants/tablePage";
import { getRowsPerPage } from "@core/helpers";

function useFetchTableDataByQueryParam({
  getStore,
  dataKey,
  matchPath,
  defaultParams = {},
}) {
  const location = useLocation();
  const getParsedQueryParams = () => qs.parse(location.search.slice(1));

  const initialTableView = useMemo(
    () => qs.parse(location.search.slice(1)).view,
    [],
  ); // TODO this "view" should be kept in some constant
  const lastTableView = useRef(initialTableView);
  const firstRender = useRef(true);
  const lastTablePage = useRef(0);

  const getTablePage = () => {
    const queryParams = getParsedQueryParams();
    const tablePageStr = queryParams[TABLE_PAGE]?.toString();
    const tablePage = tablePageStr ? parseInt(tablePageStr, 10) : 0;

    return tablePage;
  };

  const switchToPageFromUrl = (isInitial) => {
    const queryParams = getParsedQueryParams();
    const tablePage = getTablePage();

    const updateTableData = () => {
      const limit = getRowsPerPage(matchPath);
      const offset = tablePage <= 0 ? 0 : tablePage * limit;
      getStore()[dataKey].load({
        ...defaultParams,
        sort: queryParams?.sort || undefined,
        limit,
        offset,
      });
    };

    if (isInitial) {
      updateTableData();

      return;
    }

    if (
      !isInitial &&
      (lastTablePage.current !== tablePage ||
        lastTableView.current !== queryParams.view)
    ) {
      updateTableData();
    }
  };

  useEffect(() => {
    switchToPageFromUrl(true);
  }, []);

  useEffect(() => {
    !firstRender.current && switchToPageFromUrl(false);
    firstRender.current = false;

    lastTableView.current = getParsedQueryParams().view;
    lastTablePage.current = getTablePage();
  }, [location.search]);
}

export default useFetchTableDataByQueryParam;
