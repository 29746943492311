import React from "react";
import modules from "@core/constants/modules";
import TestsProgressBar from "@core/components/TestsProgressBar";
const getColumns = (currentPage, module) => [
  {
    isSorted: "true",
    dataIndex: "name",
    title: "Name",
    render: (campaign) => campaign.name || "-",
  },
  {
    isSorted: "true",
    dataIndex: "creator",
    title: currentPage.includes("suppliers")
      ? // module === modules.END_OWNER ||
        // (currentPage === "/suppliers/orders/campaigns" &&
        //   module === modules.PRODUCER)
        "Manufacturer"
      : "Customer",
    render: (campaign) =>
      currentPage.includes("suppliers")
        ? campaign.manufacturer.name
        : campaign.customer
          ? campaign.customer.name
          : "",
  },
  {
    isSorted: "true",
    dataIndex: "purchaseOrder",
    title: "Customer PO",
    render: (campaign) => campaign.purchaseOrder.orderNumber,
  },
  {
    isSorted: "true",
    dataIndex: "type",
    title: "Type",
    render: (campaign) => campaign.coatingType || campaign.type,
  },
  {
    dataIndex: "status",
    title: "Status",
    width: 150,
    render: (campaign) => {
      return <TestsProgressBar isLoaded tests={campaign.tests} />;
    },
  },
];

export { getColumns };
