import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tabs, Tab } from "@mui/material";
import { withStyles } from "tss-react/mui";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import styles from "./styles";
import { FEATURES } from "@core/constants/features";
import useStores from "../../../useStores";

const ACTIONS = {
  IMPORT_DATA: "Import tests and reports",
  CREATE_TEST: "Create tests",
};

const TITLE = "Add Tests and Reports";

const AddDataDrawer = observer(
  ({
    classes,
    title = TITLE,
    open,
    close,
    ImportData,
    CreateTests,
    ...props
  }) => {
    const { UserStore, CompanyStore } = useStores();

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const touched = useRef(false);

    const canParseCsv = UserStore.features[FEATURES.PARSE_CSV];
    const canParseXls = UserStore.features[FEATURES.PARSE_XLS];

    const COMPONENT_BY_ACTION = {
      [ACTIONS.IMPORT_DATA]: ImportData,
      [ACTIONS.CREATE_TEST]: CreateTests,
    };

    const displayImport = (canParseCsv || canParseXls) && !!ImportData;
    const displayCreateTests = !!CreateTests;

    const [action, setAction] = useState(
      displayImport ? ACTIONS.IMPORT_DATA : ACTIONS.CREATE_TEST,
    );

    useEffect(() => {
      CompanyStore.getCompaniesMetaData();
    }, []);

    const onClose = useCallback(() => {
      if (!touched.current) close();
      else setConfirmationOpen(true);
    }, [touched.current]);

    const Component = COMPONENT_BY_ACTION[action] || null;

    return (
      <>
        <ResizableDrawer open={open} close={onClose} minWidth={850}>
          <DrawerHeader close={onClose} text={title} />
          {(displayImport || displayCreateTests) && (
            <div className={classes.tabs}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={action}
                onChange={(e, value) => setAction(value)}
              >
                {displayImport && (
                  <Tab
                    label={ACTIONS.IMPORT_DATA}
                    value={ACTIONS.IMPORT_DATA}
                  />
                )}
                {displayCreateTests && (
                  <Tab
                    label={ACTIONS.CREATE_TEST}
                    value={ACTIONS.CREATE_TEST}
                  />
                )}
              </Tabs>
            </div>
          )}
          <div className={classes.content}>
            <Component touched={touched} close={close} {...props} />
          </div>
        </ResizableDrawer>
        <ConfirmationOnModalClose
          confirmationOpen={confirmationOpen}
          setConfirmationOpen={setConfirmationOpen}
          onClose={close}
        />
      </>
    );
  },
);

export default withStyles(AddDataDrawer, styles);
