import React, { memo, useMemo, useEffect, useCallback, useRef } from "react";
import Stats from "stats.js";
import Konva from "konva";
import { Layer } from "react-konva";
import UnweldedPipesList from "./components/UnweldedPipesList";
import BaseMaterialPipe from "./components/BaseMaterialPipe";
import QuarantinedPipe from "../Stalk/components/QuarantinedPipe";
import Stalk from "../Stalk";
import {
  BASE_MATERIALS_LABEL,
  QUARANTINE_LABEL,
  STAGE_CONFIG,
  VIEWS,
} from "../../constants";
import { ANIMATION_DURATION, PIPE_CONFIG, WELD_CONFIG } from "./constants";
import {
  getNumberOfStalkItemsToFitIOneRow,
  getNumberOfStalkItemsInRow,
} from "../../helpers";

const stats = new Stats();
const STATS_ID = "hover";

const GlobalView = ({
  scale,
  settings,
  stageWidth,
  stalks,
  baseMaterials,
  quarantinePipes,
  layer,
  tieInWeldsByStalkNumber,
  label,
  ...props
}) => {
  const stalksRef = useRef({});

  const numberOfStalkItemsToFitIOneRow = useMemo(() => {
    const numberOfStalkItemsToFitIOneRow = getNumberOfStalkItemsToFitIOneRow(
      VIEWS.GLOBAL,
      settings,
      stageWidth,
      PIPE_CONFIG,
      WELD_CONFIG,
    );

    return numberOfStalkItemsToFitIOneRow;
  }, [stageWidth, settings.welds]);

  const numberOfStalkItemsInRow = useMemo(() => {
    const numberOfStalkItemsInRow = getNumberOfStalkItemsInRow(
      stalks,
      settings,
      numberOfStalkItemsToFitIOneRow,
    );

    return numberOfStalkItemsInRow;
  }, [numberOfStalkItemsToFitIOneRow, settings.splitStalk]);

  const numberOfPipesInRow = useMemo(() => {
    if (settings.welds) return numberOfStalkItemsInRow * 2 - 1;

    return numberOfStalkItemsInRow;
  }, [settings.welds, numberOfStalkItemsInRow]);

  const unweldedPipes = useMemo(() => {
    const unweldedPipes = [];

    if (settings.baseMaterials && baseMaterials?.length)
      unweldedPipes.push({
        label: BASE_MATERIALS_LABEL,
        pipes: baseMaterials,
        component: BaseMaterialPipe,
      });

    if (settings.quarantine && quarantinePipes?.length)
      unweldedPipes.push({
        label: QUARANTINE_LABEL,
        pipes: quarantinePipes,
        component: QuarantinedPipe,
      });

    return unweldedPipes;
  }, [
    settings.baseMaterials,
    settings.quarantine,
    baseMaterials?.length,
    quarantinePipes?.length,
  ]);

  const addStats = useCallback(() => {
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(stats.dom);
    stats.dom.style.position = "absolute";
    stats.dom.style.left = "100px";
    stats.dom.style.top = "100px";
    stats.dom.id = STATS_ID;
  }, []);

  const removeStats = useCallback(() => {
    const statsBlock = document.getElementById(STATS_ID);

    if (!statsBlock) return;

    document.body.removeChild(statsBlock);
  }, []);

  useEffect(() => {
    if (!settings.showStats) removeStats();
    else addStats();
  }, [settings.showStats]);

  const animateShape = useCallback((node, options) => {
    if (!node || !node._id) return;

    stats.begin();

    const animation = new Konva.Tween({
      node: node,
      ...options,
      easing: Konva.Easings.EaseInOut,
      duration: ANIMATION_DURATION,
      onFinish: () => {
        // remove all references from Konva
        animation.destroy();
      },
    });

    animation.play();

    stats.end();
  }, []);

  return (
    <Layer ref={layer} style={{ background: STAGE_CONFIG.BACKGROUND_COLOR }}>
      {stalks.map((stalk, stalkIndex) => (
        <Stalk
          key={stalkIndex}
          stalk={stalk}
          stalkIndex={stalkIndex}
          settings={settings}
          scale={scale}
          animateShape={animateShape}
          tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
          stalks={stalks}
          stalksRef={stalksRef}
          label={label}
          numberOfStalkItemsInRow={numberOfStalkItemsInRow}
          pipeConfig={PIPE_CONFIG}
          weldConfig={WELD_CONFIG}
          {...props}
        />
      ))}
      <UnweldedPipesList
        unweldedPipes={unweldedPipes}
        settings={settings}
        scale={scale}
        animateShape={animateShape}
        storedY={stalksRef.current[stalks.length - 1]}
        numberOfPipesInRow={numberOfPipesInRow}
        {...props}
      />
    </Layer>
  );
};

export default memo(GlobalView);
