import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { keys, values, reject, equals } from "ramda";
import { observer } from "mobx-react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import { withStyles } from "tss-react/mui";
import { Formik, FieldArray } from "formik";
import { IoMdCloseCircleOutline } from "react-icons/io";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import NormAutocomplete from "@core/components/NormAutocomplete";
import EditableCell from "@core/components/EditableCell";
import CampaignAutocomplete from "../CampaignAutocomplete";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import { getLocationAddress } from "@core/helpers";
import modules from "@core/constants/modules";
import useStores from "../../../useStores";
import styles from "./styles";
import { MdAdd } from "react-icons/md";

const removeEmptyProperties = (object) => reject(equals(""))(object);

const CAMPAIGN = {
  campaignId: "",
  qcpItem: "",
  woItem: "",
  poItem: "",
};

const initialValues = {
  status: "status",
  securedBy: "securedBy",
  properties: {
    productType: "",
    quantity: "",
    norm: "",
    grade: "",
    location: "",
    notes: "",
    heat: "",
    lot: "",
    customProperties: {},
  },
  campaigns: [],
};

const validationSchema = yup.object().shape({
  properties: yup.object().shape({
    productType: yup.object().required("This field is required!"),
    quantity: yup.number().required("This field is required!"),
    norm: yup.string(),
    grade: yup.string(),
    location: yup.string(),
    notes: yup.string(),
    heat: yup.string(),
    lot: yup.string(),
    customProperties: yup.lazy((item) => {
      return yup.object().shape({
        ...keys(item).reduce((acc, key) => {
          acc[key] = yup.string().required("This field is required!");

          return acc;
        }, {}),
      });
    }),
  }),
  campaigns: yup.array().of(
    yup.object().shape({
      campaignId: yup.string().required("This field is required!"),
      qcpItem: yup.string(),
      woItem: yup.string(),
      poItem: yup.string(),
    }),
  ),
});

const ProductForm = observer(({ classes, open, close, product = {} }) => {
  const [grades, setGrades] = useState([]);

  const { ProductStore, UserStore } = useStores();

  useFetchTestNorms("certificate", product.properties?.norm, (gradesByNorm) =>
    setGrades(values(gradesByNorm)),
  );

  const onSubmit = async (values) => {
    const data = {
      ...values,
      properties: {
        ...removeEmptyProperties(values.properties),
        productType: values.properties.productType._id,
        quantity: Number(values.properties.quantity),
      },
      campaigns: values.campaigns.map((campaign) =>
        removeEmptyProperties(campaign),
      ),
    };

    if (product._id) {
      await ProductStore.updateById(product._id, data);
    } else {
      await ProductStore.create(data);
    }

    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>
        {product._id ? "Update product" : "Create product"}
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnMount
          initialValues={{ ...initialValues, ...product }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => {
            const usedCampaigns = props.values.campaigns.map(
              (campaign) => campaign.campaignId,
            );

            const { mainLocation, locations } = UserStore.user.data.company;
            const [module] = UserStore.user.data.company.modules;

            useEffect(() => {
              props.validateForm();
            }, [props.values.properties.productType?._id]);

            return (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <ProductTypesAutocomplete
                    inputProps={{
                      label: "Product type",
                      name: "properties.productType",
                      required: true,
                    }}
                    onChange={(value) => {
                      const customProperties = value.properties?.reduce(
                        (acc, property) => ({
                          ...acc,
                          [property.name]: "",
                        }),
                        {},
                      );

                      props.setFieldValue(
                        "properties.productType",
                        value || "",
                      );
                      props.setFieldValue(
                        "properties.customProperties",
                        customProperties || {},
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    type="number"
                    label="Quantity"
                    name="properties.quantity"
                  />
                </Grid>
                <Grid item xs={3}>
                  <NormAutocomplete
                    required={false}
                    label="Material specification"
                    name="properties.norm"
                    testType="certificate"
                    onChange={({ norm, value: grades }) => {
                      props.setFieldValue("properties.norm", norm);
                      setGrades(grades || []);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    disabled={!props.values.properties.norm}
                    name="properties.grade"
                    label="Grade"
                  >
                    {grades.map((value) => (
                      <MenuItem key={value.Material} value={value.Material}>
                        {value.Material}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                {props.values.properties.productType.properties?.map(
                  (property) => (
                    <Grid item xs={3}>
                      <TextField
                        required
                        label={property.label}
                        name={`properties.customProperties.${property.name}`}
                        endAdornment={property.measurements}
                      />
                    </Grid>
                  ),
                )}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <TextField label="Heat No." name="properties.heat" />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label="Lot ID" name="properties.lot" />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField label="Location" name="properties.location">
                        <MenuItem
                          key={mainLocation._id}
                          value={mainLocation._id}
                        >
                          {getLocationAddress(mainLocation)}
                        </MenuItem>
                        {locations.map((location) => (
                          <MenuItem key={location._id} value={location._id}>
                            {getLocationAddress(location)}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      rows={4}
                      multiline
                      label="Notes"
                      name="properties.notes"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {!!props.values.campaigns.length && (
                      <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                          <h3>Campaigns</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <Table className="styled-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Campaign</TableCell>
                                <TableCell>QCP/ITP Item</TableCell>
                                <TableCell>Work order Item</TableCell>
                                <TableCell>PO Item</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray name="campaigns">
                                {({ remove }) =>
                                  props.values.campaigns.map(
                                    (campaign, index) => (
                                      <TableRow hover key={index}>
                                        <TableCell>
                                          <CampaignAutocomplete
                                            key={index}
                                            name={`campaigns.${index}.campaignId`}
                                            required
                                            usedCampaigns={usedCampaigns}
                                            isCreatable={
                                              module.name === modules.END_OWNER
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EditableCell
                                            editable
                                            value={campaign.qcpItem}
                                            setValue={(value) =>
                                              props.setFieldValue(
                                                `campaigns.${index}.qcpItem`,
                                                value,
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EditableCell
                                            editable
                                            value={campaign.woItem}
                                            setValue={(value) =>
                                              props.setFieldValue(
                                                `campaigns.${index}.woItem`,
                                                value,
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EditableCell
                                            editable
                                            value={campaign.poItem}
                                            setValue={(value) =>
                                              props.setFieldValue(
                                                `campaigns.${index}.poItem`,
                                                value,
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            className={classes.removeButton}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              remove(index);
                                            }}
                                          >
                                            <IoMdCloseCircleOutline />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ),
                                  )
                                }
                              </FieldArray>
                              <TableRow colSpan={5}>
                                <TableCell>
                                  <Button
                                    onClick={() => {
                                      props.setFieldValue("campaigns", [
                                        ...props.values.campaigns,
                                        CAMPAIGN,
                                      ]);
                                    }}
                                  >
                                    <MdAdd size={80} />
                                    Add new
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          {!props.values.campaigns.length && (
                            <Button
                              onClick={() => {
                                props.setFieldValue("campaigns", [
                                  ...props.values.campaigns,
                                  CAMPAIGN,
                                ]);
                              }}
                              color="primary"
                              variant="contained"
                            >
                              Add campaign
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={!props.isValid}
                            onClick={props.handleSubmit}
                            color="primary"
                            variant="contained"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(ProductForm, styles);
