import CampaignCreate from "@core/components/CampaignCreate";
import ColumnsSelect from "@core/components/ColumnsSelect";
import { Input } from "@core/components/Form";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import modules from "@core/constants/modules";
import { STORE_DATA } from "@core/constants/storeDataKeys";
import { setRowsPerPage } from "@core/helpers";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { observer } from "mobx-react-lite";
import { map, prop } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import useStores from "../../useStores";
import { columns as getColumns } from "./columns";
import { ACCESS_BY_TAB, TAB_ORDER_BY_MODULE } from "./constants";
import styles from "./styles";

const CampaignList = observer(({ classes, direction }) => {
  const [campaignToDeleteId, setCampaignToDeleteId] = useState(null);
  const [createNewFormOpen, setCreateNewFormOpen] = useState(false);

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { CampaignStore, UserStore } = useStores();
  const { status, data: campaigns, total, fetch } = CampaignStore.campaigns;

  const [module] = UserStore.user.data.company.modules;

  const columns = getColumns(location.pathname, module, setCampaignToDeleteId);

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(
    savedSelectedColumns || map(prop("dataIndex"), columns),
  );

  useEffect(() => {
    return () => CampaignStore.campaigns.reset();
  }, []);

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    CampaignStore.campaigns.load({ limit, offset: 0 });
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    CampaignStore.campaigns.load({ search, offset: 0 });
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => CampaignStore,
    dataKey: STORE_DATA.campaigns,
    matchPath: match.path,
    defaultParams: { view: ACCESS_BY_TAB[direction] },
  });

  return (
    <div className={classes.page}>
      <Grid container={true} className={classes.pageHeadline} spacing={3}>
        <Grid item={true}>
          <Typography variant="h4" fontSize="1.8rem">
            Campaigns
          </Typography>
        </Grid>
        <Grid item={true}>
          {module.name !== modules.WITNESS && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateNewFormOpen(true)}
            >
              Create new
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container={true} justifyContent="space-between">
        <Grid item={true} xs={true}>
          <Input
            name="search"
            endAdornment={
              <InputAdornment position="end">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Start typing to search"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item={true}>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
      </Grid>
      <Box className={classes.pageTable}>
        <Table
          selectedColumns={selectedColumns}
          items={campaigns}
          columns={columns}
          isLoaded={status.loaded}
          sort={fetch.sort}
          setSort={(sort) => CampaignStore.campaigns.load({ sort })}
          saveSortToQueryParams
          total={total}
          onRowClick={(campaign) =>
            history.push(
              `${location.pathname}/${campaign.type.toLowerCase()}/${campaign._id}`,
            )
          }
          noDataText="No available campaigns."
        >
          <TableFooter
            saveTablePageToQueryParams
            isLoaded={status.loaded}
            items={campaigns}
            total={total}
            limit={fetch.limit}
            offset={fetch.offset}
            onOffsetChange={(offset) =>
              CampaignStore.campaigns.load({ offset })
            }
            onLimitChange={handleRowsPerPageChange}
          />
        </Table>
      </Box>
      <Confirmation
        open={campaignToDeleteId}
        onCancel={() => setCampaignToDeleteId(null)}
        onConfirm={() => {
          CampaignStore.deleteCampaignById(campaignToDeleteId);
          setCampaignToDeleteId(null);
        }}
        alertText={"Are you sure you want to remove the campaign?"}
      />
      <CampaignCreate
        open={createNewFormOpen}
        close={() => setCreateNewFormOpen(false)}
        onCreated={(campaign) =>
          history.push(
            `${location.pathname}/${campaign.type.toLowerCase()}/${campaign._id}`,
          )
        }
      />
    </div>
  );
});

export default withStyles(CampaignList, styles);
