import React, { useState } from "react";
import { Input } from "@core/components/Form";
import classNames from "classnames";
import CustomCircularProgress from "./components/CustomCircularProgress";
import { withStyles } from "tss-react/mui";
import styles from "./styles";

function MuiAutocompleteInput({
  value: valueFromProp,
  required,
  inputProps,
  InputProps,
  inputLabel,
  showError,
  errorMessage,
  isLoading,
  classes,
}) {
  const [value, setValue] = useState(valueFromProp);

  return (
    <Input
      name={inputProps.id}
      value={value}
      inputRef={InputProps.ref}
      label={inputLabel}
      required={required}
      inputProps={inputProps}
      error={showError}
      errorMessage={showError ? errorMessage : null}
      className={classNames(InputProps.className, classes.inputOverride)}
      onChange={(synthEvent) => {
        inputProps.onChange(synthEvent);
        setValue(synthEvent.target?.value);
      }}
      customAdornment={
        isLoading ? <CustomCircularProgress /> : InputProps.endAdornment
      }
      customClasses={{
        input: classes.input,
      }}
    />
  );
}

// export default MuiAutocompleteInput;
export default withStyles(MuiAutocompleteInput, styles);
