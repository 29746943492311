import React, { useMemo, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import MenuGroup from "../MenuGroup";
import useStores from "../../../../../../../../../useStores";
import { MENU_BY_MODULE } from "../../../../constants";
import { getMenuItemEnabled } from "../../services";

const MenuList = ({ expanded }) => {
  const { UserStore } = useStores();

  const [module] = UserStore.user.data.company.modules;
  const role = UserStore.user.data.role;
  const features = UserStore.features;

  const menuGroups = useMemo(() => {
    return MENU_BY_MODULE[module.name].reduce((acc, group) => {
      const items = group.items
        .filter((item) => getMenuItemEnabled(item, features, role, module))
        .map((item) => {
          const items = item.items
            ? item.items.filter((item) =>
                getMenuItemEnabled(item, features, role, module),
              )
            : [];

          return { ...item, items };
        });

      if (items.length) acc.push({ ...group, items });

      return acc;
    }, []);
  }, [UserStore.user.data._id, UserStore.features]);

  return (
    <>
      <Box
        sx={{
          marginBottom: "45px",
        }}
      >
        <img height={40} src="/images/black-logo.svg" />
      </Box>
      <Box
        sx={{
          alignSelf: expanded && "center",
          width: "100%",
          height: "82%",
          overflow: "scroll",
        }}
      >
        {menuGroups.map((group, index) => (
          <React.Fragment key={index}>
            {!!index && (
              <Box
                sx={{
                  padding: "12px 0",
                }}
              >
                <Divider />
              </Box>
            )}
            <Box>
              <MenuGroup
                expanded={expanded}
                title={group.title}
                items={group.items}
              />
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default MenuList;
