export const CAMPAIGN_TYPES = {
  COATING: "Coating",
  WELDING: "Welding",
  SINGLE_MATERIAL: "Single Material",
};

export const CAMPAIGN_SPECIMEN_LABELS = {
  PIPE: "Pipe ID",
  WELD: "Weld ID",
};

export const CAMPAIGN_ACCESS = {
  CREATOR: "creator",
  MANUFACTURER: "manufacturer",
};

export const CAMPAIGN_VIEW = {
  CUSTOMER: "customer",
  SUPPLIER: "supplier",
};
