import React, { useState } from "react";
import {
  NavLink,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Box } from "@mui/material";
import IconistsIcon from "@core/components/IconistsIcon";

import "./menuItem.css";

const MenuItem = ({ menuItem, expanded }) => {
  const location = useLocation();

  // Expand sub items of the menu
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    location.pathname.includes(menuItem.linkTo),
  );
  const hasSubMenu = menuItem.items && menuItem.items.length > 0;

  const handleMenuButtonClick = (e) => {
    if (hasSubMenu) {
      setIsSubMenuOpen(!isSubMenuOpen);
    }
  };

  const exact = menuItem.linkTo === "/" || menuItem.linkTo === "/admin";
  return (
    <Box sx={{}}>
      <NavLink
        id="menu"
        exact={exact}
        to={!hasSubMenu && menuItem.linkTo}
        onClick={handleMenuButtonClick}
        className={({ isActive }) => (isActive ? "active" : "menu-item")}
      >
        <IconistsIcon width="24px" icon={menuItem.icon} color="#AFAFAF" />

        {expanded && menuItem.title}
        {expanded && hasSubMenu && (
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
            }}
          >
            {isSubMenuOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </Box>
        )}
      </NavLink>
      {expanded && isSubMenuOpen && menuItem.items && (
        <Box
          sx={{
            marginTop: "8px",
            marginLeft: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {menuItem.items.map((item) => (
            <MenuItem menuItem={item} expanded={expanded} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MenuItem;
