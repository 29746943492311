import { CAMPAIGN_VIEW } from "@core/constants/campaign";
import modules from "@core/constants/modules";

export const TABS = {
  INCOMING: "incoming",
  OUTGOING: "outgoing",
  ALL: "all",
};

export const TAB_ORDER_BY_MODULE = {
  [modules.PRODUCER]: [TABS.OUTGOING, TABS.INCOMING, TABS.ALL],
  [modules.END_OWNER]: [TABS.INCOMING, TABS.OUTGOING, TABS.ALL],
};

export const TABS_TITLES = {
  [TABS.ALL]: "All",
  [TABS.INCOMING]: "Incoming",
  [TABS.OUTGOING]: "Outgoing",
};

export const ACCESS_BY_TAB = {
  [TABS.INCOMING]: CAMPAIGN_VIEW.CUSTOMER,
  [TABS.OUTGOING]: CAMPAIGN_VIEW.SUPPLIER,
};
